import { render, staticRenderFns } from "./Invoicereview2.vue?vue&type=template&id=0cf54996&scoped=true"
import script from "./Invoicereview2.vue?vue&type=script&lang=js"
export * from "./Invoicereview2.vue?vue&type=script&lang=js"
import style0 from "./Invoicereview2.vue?vue&type=style&index=0&id=0cf54996&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cf54996",
  null
  
)

export default component.exports